

.county-column-selector {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span{
        font-weight: bold;
    }
   
}