@import url(https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap);
.map__card {
    max-height: 90%;
}

.hidden {
    display: none;
}

.home--content {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    flex-wrap: wrap;
}

.map--container {
    min-width: 1500px;
    flex-grow: 1;
}

.map--toggles {
    width: 50em;
    opacity: 1;
}

.map--toggles:hover {
    opacity: 1;
}


.cctv-div-title{
    color: var(--ion-color-dark);
    font-size: x-large;
}

.camera-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    flex-wrap: wrap-reverse;
}

.main-camera {
    width: 100em;
    height: 100%;
    display: flex;
    justify-content: center;
}

@media only screen and (min-width: 1983px) {
    .camera-index {
        width: 35em;
        max-height: 80em;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        overflow-y: auto;
    }
}

@media only screen and (max-width: 1983px) {
    .camera-index {
        width: 100%;
        max-height: 55em;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
        overflow-y: auto;
    }
}

@media only screen and (max-width: 2220px) {
    .map--toggles {
        position: absolute;
        right: 0;
        bottom: 0;
        opacity: .5;
        width: 25em;
    }
    @media only screen and (min-height: 1080px) {
        .map--toggles {
            position: absolute;
            right: 0;
            top: 0;
            opacity: .5;
            width: 25em;
            height: 35em;
        }
    }
}

.key {
    width: 100%;
    min-height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}


.key-box {
    min-width: 3em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border: solid black 2px;
}

.red-key {
    background-color: red;
}

.orangered-key {
    background-color: orangered;
}

.gold-key {
    background-color: gold;
    color: black;
}

.greenyellow-key {
    background-color: greenyellow;
    color: black;
}

.palegreen-key {
    background-color: palegreen;
    color: black;
}

.mediumseagreen-key {
    background-color: mediumseagreen;
}

.forestgreen-key {
    background-color: forestgreen;
}


.map-div {
    text-align: left !important;
  }

 
  .ion-list-bg{
    --ion-background-color:#f4f5f8 !important; 
  }

  .header-font {
    font-size: 1.5em !important;
  }

  .offset-title
  {
    marker-start: 5px !important;
  }

@media only screen and (min-width: 1023px) {
    /* .box {
      display: flex;
      justify-content: space-around;
    } */

    .box {
        position: relative; /* Ensures the .controls-box can be positioned absolutely relative to this container */
    }
  
    .controls-box {
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  
    .map-div {
      /* width: 65%; */
      width: 100%;
      position: relative;
    }
  
    .controls-box {
        position: absolute;
        top: 0;
        right: 0;
        max-height: 45em;
        width: 25em;
        overflow-y: auto;
        padding-top: 13px;
        padding-right: 16px;
        /* background-color: rgba(255, 255, 255, 0.9);
        z-index: 10; 
      max-height: 45em;
      box-shadow: 0 4px 6px rgba(0,0,0,0.1); */

    }

  

    


  }

.react-player-container {
    width: 100%;
    height: 100%;
    display: flex;
    place-content: center;
}

.active-player-card {
    height: 100%;
    width: 100%;
}

.react-player-card{
    text-align: left;
    width: 30em;
    min-height: 25em;
}

.react-player-card-title{
    color: var(--ion-color-dark);
    font-size: large;
}

.react-player-card-header{
    padding: 0 0 0 1em;
    border-bottom: .1em solid var(--ion-color-medium);
}


.bg-video{
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}


.home-background{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.display-cards {
    height: 60%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }


.display-cards ion-card{
  height: 19em;
  width: 22em;
  display:flex;
  justify-content:center;  
  align-items: center;
  flex-direction: column;
  background-color: rgba(76, 78, 76, 0.8);
  color: white;
  transition: box-shadow 0.3s ease, -webkit-transform 0.3s ease;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  transition: transform 0.3s ease, box-shadow 0.3s ease, -webkit-transform 0.3s ease;
  border-radius: 20px;
} 

.display-cards ion-card:hover {
  cursor: pointer;
  -webkit-transform: scale(1.2);
          transform: scale(1.2); 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}

.icon-style{
  height: 3em;
  width: 3em;
}


.disclaimer{
  padding-left: 2em;
  padding-right: 2em;
}


.disclaimer ion-card{
  background-color: rgba(76, 78, 76, 0.8);
  color: white;
  transition: box-shadow 0.3s ease, -webkit-transform 0.3s ease;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  transition: transform 0.3s ease, box-shadow 0.3s ease, -webkit-transform 0.3s ease;
  border-radius: 20px;
}

.disclaimer-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-size:medium;
}





.card-grid{
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-template-rows: 25%;
}


.my-card{
    height: 10em;
    width: 15em;
    display:flex ;
    justify-content:center ;  
    align-items: center ;
    flex-direction: column ;
    border-radius: 20px;
    text-align: center;
    background-color: rgba(76, 78, 76, 0.8);
    margin:auto;
    color: white;
    transition: box-shadow 0.3s ease, -webkit-transform 0.3s ease;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    transition: transform 0.3s ease, box-shadow 0.3s ease, -webkit-transform 0.3s ease; /* Add smooth transition */
    position: relative;
}

.my-card:hover{
  cursor: pointer;
  -webkit-transform: scale(1.2);
          transform: scale(1.2); 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}





.custom-icon-size {
    font-size: 60px; 
    
}

.safety{
  color: green;
}

.livedata{
  color: aqua;
}

.traffic{
  color: brown;
}

.motor{
   color: chocolate;
}

.operations{
  color:hotpink
}



.bg-video{
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
.outer-tutorial-card {
    height: 21em;
    overflow: visible;
    margin: 1rem 0;
}

.tutorial-content-container {
    width: 100%;
    height: 12em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.tutorial-button {
    width: 15em;
    color: black;
    /* padding-bottom: 1em; */
}

.tutorial-card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
    width: 10rem;
    border-radius: 3px;
    margin: -2rem 0 0 1rem;
    padding-top: 25px;
}

.footer-row {
    display: flex;
    justify-content: center;
}

.card-dark {
    /* background-color: var(--ion-color-dark); */
    box-shadow: 2px 7px 8px rgba(var(--ion-color-dark), 0.2);
}

.card-pink {
    background-color: var(--ion-color-pink);
    box-shadow: 2px 7px 8px rgba(var(--ion-color-pink), 0.2);
}

.card-purple {
    background-color: var(--ion-color-purple);
    box-shadow: 2px 7px 8px rgba(var(--ion-color-purple), 0.2);
}

.card-yellow {
    background-color: var(--ion-color-yellow);
    box-shadow: 2px 7px 8px rgba(var(--ion-color-yellow), 0.2);
}

.top-border {
    border-top: 1px solid #d7d8da;
}

.card-content-md {
    width: 100% !important;
}

@media screen and (max-width: 480px) {
    .card-title{
        font-size: 1.5em;
    }
}
.tour-card {
    margin-bottom: 2em;
}

/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #007FAD;
  --ion-color-primary-rgb: 98, 110, 212;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #4A53A1;
  --ion-color-primary-tint: #7381FA;

  /** secondary **/
  --ion-color-secondary: #0090DA;
  --ion-color-secondary-rgb: 121, 132, 218;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #5C65A6;
  --ion-color-secondary-tint: #8D92FF;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222736;
  --ion-color-dark-rgb: 34, 39, 54;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #191D28;
  --ion-color-dark-tint: #3A425C;

  /** medium **/
  --ion-color-medium: #015990;
  --ion-color-medium-rgb: 1, 89, 144;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #00395D;
  --ion-color-medium-tint: #0170B6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** green **/
  --ion-color-green: #91D9AB;
  --ion-color-green-rgb: 145, 217, 171;
  --ion-color-green-contrast: #000000;
  --ion-color-green-contrast-rgb: 0, 0, 0;
  --ion-color-green-shade: #6EA582;
  --ion-color-green-tint: #AAFFCE;

  /** yellow **/
  --ion-color-yellow: #F8B425;
  --ion-color-yellow-rgb: 248, 180, 37;
  --ion-color-yellow-contrast: #ffffff;
  --ion-color-yellow-contrast-rgb: 255, 255, 255;
  --ion-color-yellow-shade: #C48E1D;
  --ion-color-yellow-tint: #FFC726;

  /** red **/
  --ion-color-red: #EC4561;
  --ion-color-red-rgb: 236, 69, 97;
  --ion-color-red-contrast: #ffffff;
  --ion-color-red-contrast-rgb: 255, 255, 255;
  --ion-color-red-shade: #B9364C;
  --ion-color-red-tint: #FF4A5C;

  /** blue **/
  --ion-color-blue: #38A4F8;
  --ion-color-blue-rgb: 56, 164, 248;
  --ion-color-blue-contrast: #ffffff;
  --ion-color-blue-contrast-rgb: 255, 255, 255;
  --ion-color-blue-shade: #2C82C4;
  --ion-color-blue-tint: #399BFF;
}

.ion-color-green {
  --ion-color-base: var(--ion-color-green);
  --ion-color-base-rgb: var(--ion-color-green-rgb);
  --ion-color-contrast: var(--ion-color-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-green-shade);
  --ion-color-tint: var(--ion-color-green-tint);
}

.ion-color-yellow {
  --ion-color-base: var(--ion-color-yellow);
  --ion-color-base-rgb: var(--ion-color-yellow-rgb);
  --ion-color-contrast: var(--ion-color-yellow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-yellow-contrast-rgb);
  --ion-color-shade: var(--ion-color-yellow-shade);
  --ion-color-tint: var(--ion-color-yellow-tint);
}

.ion-color-red {
  --ion-color-base: var(--ion-color-red);
  --ion-color-base-rgb: var(--ion-color-red-rgb);
  --ion-color-contrast: var(--ion-color-red-contrast);
  --ion-color-contrast-rgb: var(--ion-color-red-contrast-rgb);
  --ion-color-shade: var(--ion-color-red-shade);
  --ion-color-tint: var(--ion-color-red-tint);
}

.ion-color-blue {
  --ion-color-base: var(--ion-color-blue);
  --ion-color-base-rgb: var(--ion-color-blue-rgb);
  --ion-color-contrast: var(--ion-color-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-shade);
  --ion-color-tint: var(--ion-color-blue-tint);
}

ion-list{
  --ion-background-color:#015990;
}

.react-dropdown-select-dropdown {
  z-index: 999999 !important;
  max-height: 20em !important;
  width: 30em !important;
  justify-content: initial !important;
}

ion-modal {
  --border-radius: 5px!important;
}
.iframe {
    width: 100%;
    height: 100%;
    border: 0;
}

.please-wait {
    position: absolute;
    z-index: 10000;
    top: 3em;
    left: 50%;
    Transform: translate(-50%, 0);
}

.connect {
    top: 7em;
}

.download__card--title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.select {
    min-width: 10em !important;
}

.select__container {
    min-width: 10em !important;
    padding-right: 2em;
    color: black;
}

.react-dropdown-select-dropdown {
    width: 100% !important;
    display: flex !important;
    justify-content: flex-end;
}

/* .download__container {
    height: 60em;
} */

.download__card {
    /* width: 100em; */
    padding: 3em;
    display:flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    grid-gap: 0.5em;
    gap: 0.5em;
}

.download__card__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .second-step-tutorial{
    font-size: 2em;
    font-weight: bold;
    }

    .database-selector{
        font-size: 1.5em;
        font-weight: bold;
    }


}



.download__header {
    display: flex;
    justify-content: flex-start;
}

.download__green {
    background-color: var(--ion-color-green);
    border-radius: 5px 5px;
}

.download__icon {
    width: 8em;
    height: 8em;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.downloads {
    width: 100em;
}

.button--spacer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.sc-ion-select-popover {
    --ion-background-color: white !important;
}

.forms-container {
    /* overflow: hidden;
    border-radius: 10px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 1.5em;
    gap: 1.5em;  
}

/* .attribute-option {
    width: 25%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
}

.attributes-container {
    width: 100%;
    display: flex;
    justify-content: flex-start !important;
    flex-wrap: wrap !important;
} */

.attributes-container {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 4em;
    gap: 4em;
}

.attribute-option {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 0.5em;
    gap: 0.5em;
    background-color: #1E90FF;
    padding: 10px;
    border-radius: 10px;
    color: white;
    font-weight: bold;
}

.query-selectors{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.file-name{
    display: flex;
    justify-content: center;
    align-items: center;

}

.radio-buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
    padding: 15px;
    ion-item {
        --border-style: none;
      }


}


.alert-class{
    border-radius: 10px;
    h3{
        padding: 10px;
    }
}


.date-time-div{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 3em;
    gap: 3em;
    color: white;
   
}

.form-item-one {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
    color: white;
    font-weight: bold;
   
    .date-time{
    background-color: #1E90FF;
    padding: 7px;
    border-radius: 10px;
    font-weight: bold;
    color: white;
    }
}

.MuiInputBase-root{
    color: antiquewhite !important;
}


/* .MuiInputBase-root{
    display: flex;
    
} */




.selectable{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 1em;
    gap: 1em;
}

.selectable-form-div{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 3em;
    gap: 3em;

    span{
        color: black;
        font-weight: bold;
    }

   
}

.form-selectors{
    background-color: #1E90FF;
    color: white;
    padding: 0px 5px;
    border-radius: 10px;
    width: 150px;
}

.selector-title{
    width: 150px;
}


.file-name{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 3em;
    gap: 3em;

    span{
        color: black;
        font-weight: bold;
    
    }
}

.input-box{
    width: 150px;
    border: 1px solid black; 
    border-radius: 5px;


}

.ion-input-custom {
    width: 70%; /* or any specific width */
}

.selector-title{
    width: 150px;
}
.county-select {
    min-width: 65vw;
}

.county-small {
    min-width: 50vw;
}

.county-form-div {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 1em;
}

.county-column {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 3em;
}



.county-column-selector {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span{
        font-weight: bold;
    }
   
}
.card--container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card--login {
    width: 50em;
    height: 35em;
    padding: 0 4em 4em;
    color: black;
}

.card--signup {
  height: 47em;

}

.titan-t {
    width: 4em;
    height: 4em;
}

.titan-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 2em;
}

.buttons-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 1em;
}

.background {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: url(/static/media/blurry_background1.4f19364f.PNG) no-repeat fixed;
    background-size: cover;
    -webkit-filter: blur(10px);
            filter: blur(10px);
}

.login-input {
    --background: var(--ion-color-light) !important;
    margin-bottom: 1em;
}



.signup-input {
  --background: var(--ion-color-light) !important;
  margin-bottom: 1em;
  width: 20em;
}

.signup-fields{
  display: flex;
  grid-gap: 2em;
  gap: 2em;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-in-logo {
  cursor: pointer;
}

.ridsi-icon {
  width: 4em;
  height: 4em;
  margin-right: 0.5em;
}

.header {
  height: 6em;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 769px) {
  .header{
    padding-right: 20vw !important;
    padding-left: 20vw !important;
  }
}

.footer {
  position: absolute;
  z-index: 0;
  bottom: 0;
}

.formItem {
  align-items: center;
}

.logo-container {
  display: flex !important;
  justify-content: space-evenly;
}

.login-card-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.login-label {
  font-size: 1.25em !important;
}

.links {
  width: 100%;
  text-align: center;
}

.link {
  --color: palevioletred;
}

.error-message {
  color: red;
  padding-top: 1em;
  padding-bottom: 1em;
  text-align: center;
}

.center {
  text-align: center;
}

/* Everything above mobile devices */
@media only screen and (min-width: 769px) {
  .card,
  .reset-card {
    width: 25em;
    min-height: 33em;
    padding-top: 10em;
    z-index: 1;
  }

  .reset-card {
    min-height: 15em;
    padding-top: 4em;
  }

  .inner-card,
  .reset-inner-card {
    position: relative;
    top: 10em;
    z-index: 2;
    height: 10em;
    width: 23em;
  }

  .reset-inner-card {
    height: 6em;
    top: 5em;
  }
  .links {
    margin-top: 1em;
    margin-bottom: 1em;
    width: 100%;
    text-align: center;
  }
  
  .link {
    --color: rgb(253, 253, 253);
    color: rgb(253, 253, 253);
  }

  .forgot-desc{
        text-align: center;
        margin-top: 2em;
        margin-bottom: 2em;

  }
}

/* Mobile devices and below */
@media only screen and (max-width: 769px) {
  .card,
  .reset-card {
    position: relative;
    z-index: 1;
    bottom: 10em;
    width: 25em;
    min-height: 33em;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .reset-card {
    min-height: 15em;
    padding-top: 4em;
    margin-top: 5em;
  }

  .inner-card,
  .reset-inner-card {
    z-index: 2;
    height: 10em;
    width: 23em;
  }

  .reset-inner-card {
    height: 6em;
  }
  .link {
    --color: rgb(253, 253, 253);
    color: rgb(253, 253, 253);
  }

  .forgot-desc{
        text-align: center;
        margin-top: 2em;
        margin-bottom: 2em;

  }

 
}


.invalid{
  border: 3px solid red;
}
.dashboard__row {
    width: 100%;
    padding: .8em;
    display: flex;
    justify-content: center;
}


/* Definitely a better way to do this than these media queries */
@media screen and (min-width: 1825px) {
    .dashboard__split {
        width: 80%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .data-card__container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .map-data--container {
        width: 36vw;
        padding-right: 5em;
    }
}

@media screen and (max-width: 1825px) {
    .dashboard__split {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
    }
    .data-card__container {
        display: grid;
        grid-template-columns: 33% 33% 33%;
        grid-template-rows: 50% 50%;
    }
}

@media screen and (max-width: 1340px) {
    .data-card__container {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 33% 33% 33%;
    }
} 

 @media screen and (max-width: 900px) {
    .data-card__container {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 18% 18% 18% 18% 18%;
        margin-bottom: 10em;
    }
}

@media screen and (max-width: 1825px) {
    .crash-counties-list.crash-counties-list {
        max-height: 33.6em;
        margin-top: 1.1em;
        overflow-y: auto;
    }
}

@media screen and (max-width: 1340px) {
    .crash-counties-list.crash-counties-list {
        max-height: 28.1em;
        margin-top: 1em;
        overflow-y: auto;
    }
} 

@media screen and (max-width: 900px) {
    .crash-counties-list.crash-counties-list {
        max-height: 30.6em;
        margin-top: 1em;
        overflow-y: auto;
    }
}

.crash-counties-list {
    max-height: 40.6em;
    margin-top: 1.1em;
    overflow-y: auto;
}


.graph-data-card{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: auto; 
    margin-bottom: 1em; 
}

.graph-data-card-item {
    flex: 1 0 25%; 
    /* width: 23%; */
    padding: 5px; 
    box-sizing: border-box; 
}


.graph-cards{
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: auto; 
    margin-bottom: 1em; 
    margin-top: 2em;
}

.graph1{
    width: 40%;
}

.graph2{
    width: 40%;
}



.workzones-card{
    padding: 1em;
}
.rule {
    height: 0.05em;
    width: 95%;
    margin: auto;
    background-color: #d7d8da;
}

.green,
.yellow,
.blue,
.red {
    position: relative;
    height: 100%;
    width: 95%;
    padding: 10px;
    border-radius: 5px;
    margin: -2rem auto auto;
}

.green {
    background-color: var(--ion-color-green);
    box-shadow: 0 7px 5px rgba(var(--ion-color-green-rgb), 0.2);
}

.red {
    background-color: var(--ion-color-red);
    box-shadow: 0 7px 5px rgba(var(--ion-color-red-rgb), 0.2);
}

.yellow {
    background-color: var(--ion-color-yellow);
    box-shadow: 0 7px 5px rgba(var(--ion-color-yellow-rgb), 0.2);
}

.blue {
    background-color: var(--ion-color-blue);
    box-shadow: 0 7px 5px rgba(var(--ion-color-blue-rgb), 0.2);
}

.icon {
    margin: 0 0.5em 0 0;
}

.graph-card {
    overflow: visible;
    margin: 1rem 0;
    font-family: 'Inter', sans-serif;
    /* height: 23.5em; */
}

.card-content {
    height: 90%;
    overflow: visible;
}

.chart__row {
    display: flex;
    justify-content: space-between;
}

/*Common Styles for all Cards*/
/*_____________________________________________________________________________________________*/

.card-main-container{
    --background: #015990;
    /* background: #283618; */
}

.data-card-title{
    display: flex;
    background-color:#004068;
    /* background-color: #dda15e; */
    padding: 10px;
}

.card-header-title{
    padding-top: 0.25em;
    font-weight: bold;
    color: aliceblue;
}

.card-graph-title{
    display: flex;
    justify-content: center;
}

.card-last-updated{
    display: flex;
    justify-content: center;
    padding: 0.25em;
    background-color: #004068;
    color: aliceblue;
}

.data-card-icon {
    width: 1.5em;
    height: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-left: auto;
    transition: background-color 0.3s ease, box-shadow 0.3s ease; 
    cursor: pointer; 
}

.alert-title{
    background-color: antiquewhite;
    display: flex;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

.alert-buttons{
    display: flex !important;
    justify-content: space-around !important;
    align-items: center !important;
    background-color: aliceblue;
}

.alert-source{
    display: flex;
    justify-content: center;
}

.remove-pointer{
    pointer-events: none;
}

/*Crashes data card styles*/
/*_____________________________________________________________________________________________*/

.crashes-data-card-icon{
    background-color: var(--ion-color-green);
}

.crashes-data-card-icon:hover {
    background-color: rgb(3, 96, 14); 
}

.gauge-container{
    display: flex;
    justify-content: center;
    align-items: center; 
    padding: 2em;
    height: 14em;
}

.gauge-container .css-bx1j65-MuiGauge-container text, 
.gauge-container .css-1uso5f2 text
{
    fill: rgba(252, 251, 251, 0.87) !important;
}


.counties-list{
    max-height: 15.6em;
    margin-top: 1.1em;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    grid-gap: 0.75em;
    gap: 0.75em;
    padding-left: 0.75em;
    padding-right: 0.75em;
    font-size: medium;
}


.county-and-percentage{
    display: flex;
    color: aliceblue;
    justify-content: space-between;
}



/*Clearance data card styles*/
/*_____________________________________________________________________________________________*/



.clearance-data-card-icon{
    background-color: var(--ion-color-yellow);
}

.clearance-data-card-icon:hover{
    background-color: rgb(149, 149, 13);
}

.clearance-gauge-container{
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 14em;
   
}

.counties-and-time{
    max-height: 15.6em;
    margin-top: 1.1em;
    overflow-y: auto;
    color: aliceblue;
    font-size: medium;
}

.county-and-arrow{
    display: flex;
    padding: 5px;
}

.circle-dot{
    margin-right: 10px;
}

.arrow{
    margin-left: auto;
    margin-right: 40px;
}



/*Freeway data card styles*/
/*_____________________________________________________________________________________________*/

.freeway-data-card-icon{
    background-color: var(--ion-color-red);
}

.freeway-data-card-icon:hover{
    background-color: rgb(212, 3, 153);
}

.freeway-gauge-container{
    display: flex;
    justify-content: center;
    align-items: center;  
    height: 14em;
}

.freeway-gauge-container .css-kg4hl1-MuiGauge-container text,
.freeway-gauge-container .css-17oit0k text
{
    fill: rgba(252, 251, 251, 0.87) !important;
}

.freeway-bar-chart{
   display: flex;
   justify-content: center;
   max-height: 15.6em;
   margin-top: 1.1em;
   overflow-y: auto;
   font-size: medium;
   width: 100%;
    
}

.barr{
    width: 70%;
    height: 60%;
}

.MuiChartsLegend-series text{
    fill: antiquewhite !important;
}


/*Congestion data card styles*/
/*__________________________________________________________________________________________________________________*/

.congestion-data-card-icon {
    background-color: var(--ion-color-blue);
}

.congestion-data-card-icon:hover{
    background-color: rgb(31, 24, 220);
}

.congestion-gauge-container{
    display: flex;
    padding-top: 2em;
    padding-bottom: 2em;
    height: 14em;
    width: 100%;
}

.congestion-gauge-container .css-m88e9q-MuiGauge-container text,
.congestion-gauge-container .css-iw48nb text
{
    fill: rgba(252, 251, 251, 0.87) !important;
}

.gauge-circle{
    width: 65%;
    display: flex;
}

.pti-tti{
    margin-left: 0.5em;
    margin-top: 3em;
    color: antiquewhite;
    width: 35%;
    display: flex;
    flex-direction: column;
    grid-gap: 0.5em;
    gap: 0.5em;
    font-weight: bold;
}

.pti{
    display: flex;
    grid-gap:0.5em;
    gap:0.5em;
    align-items: center;
}



/*WorkZones data card styles*/
/*________________________________________________________________________________________________________________________*/


.workzone-data-card-icon {
    background-color: var(--ion-color-green);
}

.crashes-data-card-icon:hover {
    background-color: rgb(3, 96, 14); 
}

.workone {
    display: flex; 
    justify-content: space-around;
    align-items: center;
}

.workzone-line-chart{
    width: 30%;
    height: 20em;
}

.bar-chart{
    width: 40%;
    height: 20em;
}



  


.fixed-size {
    flex: 1 0 1em;

}


.flex-expand {
    flex-grow: 3
}

.profile-header {
    background-color: aliceblue;
    padding-top: 2em;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 1em;
}

.profile-card {

    margin-top: 1em;
    margin-left: 2em;
    margin-right: 2em;
    margin-bottom: 2em;

}

.profile-form-card {
    margin-top: 0px;
    margin-left: 2em;
    margin-right: 2em;
    margin-bottom: 2em;

    padding: 2em;
}

.profile-form-input-width {
    width: 100%;

}

ion-input {
    --padding-end: 2em;
    --padding-start: 2em;
}

.quick-nav-title {
    color: white;
    font-size: x-large;
    padding-left: 2em;
    padding-right: 2em;
}

.center-text {
    display: flex !important;
    align-items: center !important;
}

.profile-detail-container {}

ion-item-divider {
    --ion-background-color: #111D12;
    padding-right: 2em;
}

.no-padding-col {
    padding: 0 0 0 0px;
}

.detail-label-container {
    padding-top: 1em;
}

.profile-image {
    width: 10em;
    height: 10em;
}

.no-margin-card {
    margin-top: 0px;
}
.user-status {
    display: flex;
    align-items: center;
}


.applied-user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
    max-width: 500px;
  }
  
  .email-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 500px;
  }
  
  .uid-toggle-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 500px;
  }
  
  .uid-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-right: 10px;
  }
  
  .toggle-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .email-container {
    display: flex;
    justify-content: space-between;
  }
  
  .uid-toggle-container {
    display: flex;
    align-items: flex-end;
  }
  
  .bold-label {
    font-weight: bold;
  }



.notification-overlay {
    position: fixed; /* Or 'absolute' if positioning relative to a container */
    top: 0;
    left: 0;
    right: 0;
    bottom: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other content */
}

.notification-bar{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 300px;
    color: white;
    border-radius: 5px;
    p{
        padding-top: 10px;
    }
}

.notification-green{
    background-color:green;
}

.notification-red{
    background-color: red;
}
.create-user-role {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.create-user-button {
    background-color: #0d6efd;
    color: white;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.create-user-button:hover {
    background-color: #0b5ed7;
}

.permissions {
    span {
        padding-right: 15px;
    }
}

.pages-list {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}


.each-page {
    padding: 10px;
    margin: 5px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #f0f0f0;
    color: black;
}

.each-page.selected {
    background-color: #0d6efd;
    color: white;
}


.permissions-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-weight: bold;
}
@media only screen and (min-width: 1024px) {
    .form-card {
      width: 75%;
      margin: 4em auto;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .form-card {
      margin: 3em 1em;
    }
  }
  
  .response-requested {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .form-card {
    text-align: center;
    overflow: visible;
  }
  
  .title-blue {
    background-color: var(--ion-color-blue);
    box-shadow: 2px 7px 8px rgba(var(--ion-color-blue-rgb), 0.2);
  }
  
  .title-red {
    background-color: var(--ion-color-red);
    box-shadow: 2px 7px 8px rgba(var(--ion-color-red-rgb), 0.2);
  }
  
  .title-orange {
    background-color: var(--ion-color-orange);
    box-shadow: 2px 7px 8px rgba(var(--ion-color-orange-rgb), 0.2);
  }
  
  .title-green {
    background-color: var(--ion-color-green);
    box-shadow: 2px 7px 8px rgba(var(--ion-color-green-rgb), 0.2);
  }
  
  .title-pink {
    background-color: var(--ion-color-pink);
    box-shadow: 2px 7px 8px rgba(var(--ion-color-pink-rgb), 0.2);
  }
  .title-grey {
    background-color: var(--ion-color-grey);
    box-shadow: 2px 7px 8px rgba(var(--ion-color-pink-rgb), 0.2);
  }
  
  .form-div {
    margin: 2em 0;
    text-align: center;
    display: flex;
    flex-flow: row wrap;
  }
  
  .form-item {
    flex-grow: 1;
    margin: 0 1.5em;
  }
  

  .form-input {
    --background: var(--ion-color-light) !important;
    margin-bottom: 1em;
    margin-left: 24px !important;
    margin-right: 24px !important;
    margin-top: 5 !important;
    text-align: start !important;
}
  
.county-select {
    min-width: 65vw;
}

.county-small {
    min-width: 50vw;
}

.county-form-div {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 1em;
}

.county-column {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 3em;
}

