.create-user-role {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.create-user-button {
    background-color: #0d6efd;
    color: white;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.create-user-button:hover {
    background-color: #0b5ed7;
}

.permissions {
    span {
        padding-right: 15px;
    }
}

.pages-list {
    display: flex;
    gap: 10px;
}


.each-page {
    padding: 10px;
    margin: 5px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #f0f0f0;
    color: black;
}

.each-page.selected {
    background-color: #0d6efd;
    color: white;
}


.permissions-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-weight: bold;
}