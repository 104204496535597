.dashboard__row {
    width: 100%;
    padding: .8em;
    display: flex;
    justify-content: center;
}


/* Definitely a better way to do this than these media queries */
@media screen and (min-width: 1825px) {
    .dashboard__split {
        width: 80%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .data-card__container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .map-data--container {
        width: 36vw;
        padding-right: 5em;
    }
}

@media screen and (max-width: 1825px) {
    .dashboard__split {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
    }
    .data-card__container {
        display: grid;
        grid-template-columns: 33% 33% 33%;
        grid-template-rows: 50% 50%;
    }
}

@media screen and (max-width: 1340px) {
    .data-card__container {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 33% 33% 33%;
    }
} 

 @media screen and (max-width: 900px) {
    .data-card__container {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 18% 18% 18% 18% 18%;
        margin-bottom: 10em;
    }
}

@media screen and (max-width: 1825px) {
    .crash-counties-list.crash-counties-list {
        max-height: 33.6em;
        margin-top: 1.1em;
        overflow-y: auto;
    }
}

@media screen and (max-width: 1340px) {
    .crash-counties-list.crash-counties-list {
        max-height: 28.1em;
        margin-top: 1em;
        overflow-y: auto;
    }
} 

@media screen and (max-width: 900px) {
    .crash-counties-list.crash-counties-list {
        max-height: 30.6em;
        margin-top: 1em;
        overflow-y: auto;
    }
}

.crash-counties-list {
    max-height: 40.6em;
    margin-top: 1.1em;
    overflow-y: auto;
}


.graph-data-card{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: auto; 
    margin-bottom: 1em; 
}

.graph-data-card-item {
    flex: 1 0 25%; 
    /* width: 23%; */
    padding: 5px; 
    box-sizing: border-box; 
}


.graph-cards{
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: auto; 
    margin-bottom: 1em; 
    margin-top: 2em;
}

.graph1{
    width: 40%;
}

.graph2{
    width: 40%;
}



.workzones-card{
    padding: 1em;
}