.react-player-container {
    width: 100%;
    height: 100%;
    display: flex;
    place-content: center;
}

.active-player-card {
    height: 100%;
    width: 100%;
}

.react-player-card{
    text-align: left;
    width: 30em;
    min-height: 25em;
}

.react-player-card-title{
    color: var(--ion-color-dark);
    font-size: large;
}

.react-player-card-header{
    padding: 0 0 0 1em;
    border-bottom: .1em solid var(--ion-color-medium);
}
