.county-select {
    min-width: 65vw;
}

.county-small {
    min-width: 50vw;
}

.county-form-div {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 1em;
}

.county-column {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 3em;
}
