
.bg-video{
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}


.home-background{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.display-cards {
    height: 60%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }


.display-cards ion-card{
  height: 19em;
  width: 22em;
  display:flex;
  justify-content:center;  
  align-items: center;
  flex-direction: column;
  background-color: rgba(76, 78, 76, 0.8);
  color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 20px;
} 

.display-cards ion-card:hover {
  cursor: pointer;
  transform: scale(1.2); 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}

.icon-style{
  height: 3em;
  width: 3em;
}


.disclaimer{
  padding-left: 2em;
  padding-right: 2em;
}


.disclaimer ion-card{
  background-color: rgba(76, 78, 76, 0.8);
  color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 20px;
}

.disclaimer-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-size:medium;
}

