

.notification-overlay {
    position: fixed; /* Or 'absolute' if positioning relative to a container */
    top: 0;
    left: 0;
    right: 0;
    bottom: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other content */
}

.notification-bar{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 300px;
    color: white;
    border-radius: 5px;
    p{
        padding-top: 10px;
    }
}

.notification-green{
    background-color:green;
}

.notification-red{
    background-color: red;
}