

.fixed-size {
    flex: 1 0 1em;

}


.flex-expand {
    flex-grow: 3
}

.profile-header {
    background-color: aliceblue;
    padding-top: 2em;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 1em;
}

.profile-card {

    margin-top: 1em;
    margin-left: 2em;
    margin-right: 2em;
    margin-bottom: 2em;

}

.profile-form-card {
    margin-top: 0px;
    margin-left: 2em;
    margin-right: 2em;
    margin-bottom: 2em;

    padding: 2em;
}

.profile-form-input-width {
    width: 100%;

}

ion-input {
    --padding-end: 2em;
    --padding-start: 2em;
}

.quick-nav-title {
    color: white;
    font-size: x-large;
    padding-left: 2em;
    padding-right: 2em;
}

.center-text {
    display: flex !important;
    align-items: center !important;
}

.profile-detail-container {}

ion-item-divider {
    --ion-background-color: #111D12;
    padding-right: 2em;
}

.no-padding-col {
    padding: 0 0 0 0px;
}

.detail-label-container {
    padding-top: 1em;
}

.profile-image {
    width: 10em;
    height: 10em;
}

.no-margin-card {
    margin-top: 0px;
}
.user-status {
    display: flex;
    align-items: center;
}


.applied-user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
    max-width: 500px;
  }
  
  .email-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 500px;
  }
  
  .uid-toggle-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 500px;
  }
  
  .uid-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-right: 10px;
  }
  
  .toggle-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .email-container {
    display: flex;
    justify-content: space-between;
  }
  
  .uid-toggle-container {
    display: flex;
    align-items: flex-end;
  }
  
  .bold-label {
    font-weight: bold;
  }
