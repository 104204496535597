.iframe {
    width: 100%;
    height: 100%;
    border: 0;
}

.please-wait {
    position: absolute;
    z-index: 10000;
    top: 3em;
    left: 50%;
    Transform: translate(-50%, 0);
}

.connect {
    top: 7em;
}
