.download__card--title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.select {
    min-width: 10em !important;
}

.select__container {
    min-width: 10em !important;
    padding-right: 2em;
    color: black;
}

.react-dropdown-select-dropdown {
    width: 100% !important;
    display: flex !important;
    justify-content: flex-end;
}

/* .download__container {
    height: 60em;
} */

.download__card {
    /* width: 100em; */
    padding: 3em;
    display:flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    gap: 0.5em;
}

.download__card__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .second-step-tutorial{
    font-size: 2em;
    font-weight: bold;
    }

    .database-selector{
        font-size: 1.5em;
        font-weight: bold;
    }


}



.download__header {
    display: flex;
    justify-content: flex-start;
}

.download__green {
    background-color: var(--ion-color-green);
    border-radius: 5px 5px;
}

.download__icon {
    width: 8em;
    height: 8em;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.downloads {
    width: 100em;
}

.button--spacer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.sc-ion-select-popover {
    --ion-background-color: white !important;
}

.forms-container {
    /* overflow: hidden;
    border-radius: 10px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5em;  
}

/* .attribute-option {
    width: 25%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
}

.attributes-container {
    width: 100%;
    display: flex;
    justify-content: flex-start !important;
    flex-wrap: wrap !important;
} */

.attributes-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4em;
}

.attribute-option {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    background-color: #1E90FF;
    padding: 10px;
    border-radius: 10px;
    color: white;
    font-weight: bold;
}

.query-selectors{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.file-name{
    display: flex;
    justify-content: center;
    align-items: center;

}

.radio-buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 15px;
    ion-item {
        --border-style: none;
      }


}


.alert-class{
    border-radius: 10px;
    h3{
        padding: 10px;
    }
}